import React, { useEffect, useState } from "react";
import TopHeader from "../components/TopHeader/TopHeader";
import Navbar from "../components/Navbar/Navbar";
import Product from "../components/Products/Products";
import axios from "axios";
import { GET_PRODUCT_BY_CATEGORY } from "../components/Apis";
import CarouselCmp from "../components/CarouselCmp";
import WhatsappButton from "../components/WhatsappButton";
import ServiceHome from "../components/ServiceHome/ServiceHome";

const Home = () => {
  document.title = "Bandhej Hub - Home ";
  const [sareeData, setSareeData] = useState(null);
  useEffect(() => {
    const getProductBySaree = async () => {
      try {
        const response = await axios.get(`${GET_PRODUCT_BY_CATEGORY}/Saree/1`);
        setSareeData(response.data.products.reverse());
      } catch (error) {
        console.log("saree data error", error);
      }
    };
    getProductBySaree();
  }, []);
  const [dupattaData, setDupattaData] = useState(null);
  useEffect(() => {
    const getProductByDupatta = async () => {
      try {
        const response = await axios.get(
          `${GET_PRODUCT_BY_CATEGORY}/Dupatta/1`
        );
        setDupattaData(response.data.products.reverse());
      } catch (error) {
        console.log("dupatta data error", error);
      }
    };
    getProductByDupatta();
  }, []);
  const [dressData, setDressData] = useState(null);
  useEffect(() => {
    const getProductByDress = async () => {
      try {
        const response = await axios.get(`${GET_PRODUCT_BY_CATEGORY}/Dress/1`);
        setDressData(response.data.products.reverse());
      } catch (error) {
        console.log("dress data error", error);
      }
    };
    getProductByDress();
  }, []);
  const [lehangaData, setLehangaData] = useState(null);
  useEffect(() => {
    const getProductByLehanga = async () => {
      try {
        const response = await axios.get(
          `${GET_PRODUCT_BY_CATEGORY}/Lehanga/1`
        );
        setLehangaData(response.data.products.reverse());
      } catch (error) {
        console.log("Lehanga data error", error);
      }
    };
    getProductByLehanga();
  }, []);

  return (
    <>
      <TopHeader />
      <Navbar />
      <CarouselCmp />

      {/* <Product
        heading="Top Trending"
        summary="Collections Of Top Trending Items "
        allProductsData={topTrending}
        query="Top Trending"
      /> */}
      <Product
        heading="bandhani saree"
        summary="Collections Of Bandhani Sarees"
        allProductsData={sareeData}
        query="Saree"
      />

      <Product
        heading="bandhani dupatta"
        summary="Collections Of Bandhani Dupattas"
        allProductsData={dupattaData}
        query="Dupatta"
      />

      <Product
        heading="bandhani dress"
        summary="Collections Of Bandhani Dresss"
        allProductsData={dressData}
        query="Dress"
      />
      <Product
        heading="bandhani lehanga"
        summary="Collections Of Bandhani Lehangas"
        allProductsData={lehangaData}
        query="Lehanga"
      />
      <ServiceHome />
      <WhatsappButton />
    </>
  );
};

export default Home;
