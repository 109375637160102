import React from "react";
import styles from "./ServiceHome.module.css";
import {
  LiaShippingFastSolid,
  IoMdHeartEmpty,
  HiOutlineArrowPathRoundedSquare,
} from "../../assets/Icons";
const ServiceHome = () => {
  return (
    <div className={styles.serviceSectionHome}>
      <div className={styles.serviceSections}>
        <div>
          <i>
            <LiaShippingFastSolid />
          </i>
        </div>
        <div>
          <h3>Free Shipping</h3>
        </div>
        <div>
          <p>Free shipping on Orders above ₹999</p>
        </div>
      </div>
      <div className={styles.serviceSections}>
        <div>
          <i>
            <IoMdHeartEmpty />
          </i>
        </div>
        <div>
          <h3>PROUDLY MADE IN INDIA</h3>
        </div>
        <div>
          <p>Crafted by local artisans and in-house karigars</p>
        </div>
      </div>
      <div className={styles.serviceSections}>
        <div>
          <i>
            <HiOutlineArrowPathRoundedSquare />
          </i>
        </div>
        <div>
          <h3>EASY RETURN POLICY</h3>
        </div>
        <div>
          <p>Simple return and refund policy</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceHome;
