import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import styles from "./Order.module.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { GET_ORDER } from "../Apis";
import ScreenLoader from "../../assets/spinnerbandhej.svg";
const MyOrders = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [screenLoader, setScreenLoader] = useState(false);
  let accessToken = localStorage.getItem("BandhejHub");
  const [orderData, setOrderData] = useState(null);
  // {FATCH ORDER DATA I}
  useEffect(() => {
    const fetchData = async () => {
      setScreenLoader(true);
      try {
        const response = await axios.get(`${GET_ORDER}/${status}`, {
          headers: { Authorization: `Bearer ${accessToken}` },
          withCredentials: true,
        });
        setOrderData(response?.data.orders.reverse());
        setScreenLoader(false);
      } catch (error) {
        setScreenLoader(false);
        setOrderData("Order not found");
      }
    };

    if (accessToken) {
      fetchData();
    }
  }, [status, accessToken]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata", // Set to IST (Indian Standard Time)
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const truncateOrderId = (orderId) => {
    const n = orderId.length;
    if (orderId) {
      return orderId.slice(n - 8, n);
    }
  };

  return (
    <div className={styles.myordersPage}>
      {/* navigation bar  */}
      <div className={styles.productDetailsTopContainerNavigation}>
        <div>
          <Link to="/">Home</Link>
        </div>
        <div>
          <MdOutlineKeyboardArrowRight />
        </div>
        <div>Orders</div>
      </div>
      {/* category buttons  */}
      <div className={styles.myordersCategory}>
        {[
          "All",
          "Placed",
          "Confirm",
          "Dispatched",
          "Delivered",
          "Cancelled",
        ].map((itemStatus) => (
          <div
            key={itemStatus}
            onClick={() => navigate(`/myorders?status=${itemStatus}`)}
          >
            <button
              className={status === itemStatus ? styles.statusBtnActive : ""}
            >
              {itemStatus}
            </button>
          </div>
        ))}
      </div>
      {/* order list or not found conditions  */}

      <div>
        {screenLoader ? (
          <div className="screenLoader">
            <div>
              <img src={ScreenLoader} alt="loader..." />
            </div>
            <div>
              <span>Loading...</span>
            </div>
          </div>
        ) : orderData === "Order not found" ? (
          <div className={styles.ordernotfound}>
            <img
              src="https://img.freepik.com/premium-vector/order-closed-flat-vector-illustration-mobile-shopping-order-processing-status-icon-man-standing-near-huge-smartphone-with-shopping-cart-screen-digital-shopping-order-shipped-closed_241107-1213.jpg?semt=ais_hybrid"
              alt=""
            />
            <p>Oops! {status} Order Not found</p>
            <Link to="/">
              <button>Order now</button>
            </Link>
          </div>
        ) : (
          <div className={styles.myordersList}>
            {orderData?.map((item) => (
              <div
                key={item?.orderItemId}
                onClick={() => navigate(`/myorders/${item?.orderItemId}`)}
                className={styles.myordersContainer}
              >
                {/* Topper order id and amount */}
                <div className={styles.myordersCotainer1}>
                  <div>
                    <p>Order ID: #{truncateOrderId(item?.orderItemId)} </p>
                    <p>Placed on : {formatDate(item?.order.createdAt)} </p>
                  </div>
                  <div>
                    Total ₹{item?.quantity * item?.variant?.product?.showPrice}
                    .00
                  </div>
                </div>
                {/* Product list */}
                <div className={styles.myordersCotainer2}>
                  <div className={styles.myordersContainer2Left}>
                    {/* Image */}
                    <div>
                      <img src={item?.variant?.image} alt="" />
                    </div>
                    {/* Product details */}
                    <div>
                      {/* Name and status */}
                      <div>
                        <div>{item?.variant.product.name}</div>
                        <div
                          className={
                            item?.status === "Cancelled"
                              ? `${styles.myorderStatus} ${styles.myorderStatusCancel}`
                              : item?.status === "Delivered"
                              ? `${styles.myorderStatus} ${styles.myorderStatusDelivered}`
                              : styles.myorderStatus
                          }
                        >
                          {item?.status}
                        </div>
                      </div>
                      {/* Category and quantity */}
                      <p>
                        {item?.variant.product.category} | Item:{" "}
                        {item?.quantity}
                      </p>
                      {/* Price */}
                      <p>Price: ₹{item?.variant.product.showPrice}.00</p>
                    </div>
                  </div>

                  <div className={styles.myordersContainer2Right}>
                    <i>
                      <MdOutlineKeyboardArrowRight />
                    </i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
